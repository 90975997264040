<template>
    <page-title
        icon="bi-gear-fill"
        title="規格項目マスタ"
    >
    </page-title>

    <section class="section">
        <form @submit.prevent="search()" class="row align-items-end">
            <div class="form-group col-md-8">
                <label>項目名</label>
                <form-input
                    v-model="condition.label_name"
                ></form-input>
            </div>
            <div class="col-md-4">
                <button-search
                    text="絞り込み"
                    type="submit"
                    :disabled="loading"
                ></button-search>
            </div>
        </form>
    </section>

    <section class="section">
        <inline-loader v-if="loading"></inline-loader>
        <template v-else>
            <table-normal optional_class="table-auto">
                <thead>
                    <tr class="table-dark">
                        <th class="col-md-10 text-center">項目名</th>
                        <th class="col-md-7 text-center">入力形式</th>
                        <th class=" text-center" v-if="$store.getters['auth/canManageStandardSpecLabel']()"></th>
                        <th class=" text-center" v-if="$store.getters['auth/canManageStandardSpecLabel']()"></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="spec_labels.length">
                        <tr v-for="(spec_label, index) in spec_labels" :key="spec_label.spec_label_id">
                            <td class="align-middle">
                                <form-input
                                    v-if="$store.getters['auth/canManageStandardSpecLabel']()"
                                    v-model="spec_label.label_name"
                                    :required="true"
                                    maxlength="100"
                                    :form="`spec_label_${spec_label.spec_label_id}`"
                                ></form-input>
                                <read-only
                                    v-else
                                    :text="spec_label.label_name"
                                    class="text-center"
                                ></read-only>
                            </td>
                            <td class="align-middle">
                                <form-select
                                    v-if="$store.getters['auth/canManageStandardSpecLabel']()"
                                    v-model="spec_label.input_type"
                                    :options="input_types"
                                    :required="true"
                                    :form="`spec_label_${spec_label.spec_label_id}`"
                                ></form-select>
                                <read-only
                                    v-else
                                    :text="spec_label.input_type_display"
                                    class="text-center"
                                ></read-only>
                            </td>
                            <td class="align-middle text-center" v-if="$store.getters['auth/canManageStandardSpecLabel']()">
                                <form @submit.prevent="update(spec_label)" :id="`spec_label_${spec_label.spec_label_id}`">
                                    <button-exec-update
                                        text="保存"
                                    ></button-exec-update>
                                </form>
                            </td>
                            <td class="align-middle text-center" v-if="$store.getters['auth/canManageStandardSpecLabel']()">
                                <button-exec-delete
                                    type="button"
                                    @click="$refs.confirm_remove.show(); setTargetSpecLabel(spec_label, index)"
                                ></button-exec-delete>
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td colspan="3"><alert-no-record></alert-no-record></td>
                        </tr>
                    </template>
                </tbody>
                <tfoot v-if="$store.getters['auth/canManageStandardSpecLabel']()">
                    <tr>
                        <td class="align-middle">
                            <form-input
                                v-if="$store.getters['auth/canManageStandardSpecLabel']()"
                                v-model="additional_spec_label.label_name"
                                :required="true"
                                maxlength="100"
                                form="add_spec_label"
                            ></form-input>
                        </td>
                        <td class="align-middle">
                            <form-select
                                v-if="$store.getters['auth/canManageStandardSpecLabel']()"
                                v-model="additional_spec_label.input_type"
                                :options="input_types"
                                :required="true"
                                empty_option="-- 選択 --"
                                form="add_spec_label"
                            ></form-select>
                        </td>
                        <td class="align-middle">
                            <template v-if="$store.getters['auth/canManageStandardSpecLabel']()">
                            <form @submit.prevent="create" id="add_spec_label">
                                <button-exec-create
                                    text="追加"
                                ></button-exec-create>
                            </form>
                            </template>
                        </td>
                    </tr>
                </tfoot>
            </table-normal>
        </template>
    </section>

    <confirm-dialog ref="confirm_remove" @ok="remove">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import SpecLabel from '@/models/entities/spec-label';
import InputType from '@/models/enums/input-type';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormInput from '@/components/forms/FormInput';
import FormSelect from '@/components/forms/FormSelect';
import ReadOnly from '@/components/forms/ReadOnly';
import ButtonSearch from '@/components/buttons/ButtonSearch';
import ButtonExecCreate from '@/components/buttons/ButtonExecCreate';
import ButtonExecUpdate from '@/components/buttons/ButtonExecUpdate';
import ButtonExecDelete from '@/components/buttons/ButtonExecDelete';
import TableNormal from '@/components/tables/TableNormal.vue';
import AlertNoRecord from '@/components/notice/AlertNoRecord.vue';
import PageTitle from '@/components/PageTitle.vue';

export default {
    name: 'SpecLabel',
    components: {
        ConfirmDialog,
        InlineLoader,
        FormInput,
        FormSelect,
        ReadOnly,
        ButtonSearch,
        ButtonExecCreate,
        ButtonExecUpdate,
        ButtonExecDelete,
        TableNormal,
        AlertNoRecord,
        PageTitle,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
    ],
    data() {
        return {
            //部分ローダー
            loading: false,

            //検索オプション
            condition: {
                label_name: null,
            },

            //選択肢
            input_types: InputType.options(),

            //表示データ
            spec_labels: [],
            
            //新規登録規格項目
            additional_spec_label: new SpecLabel(),
            //削除対象規格項目
            delete_target: {
                spec_label: new SpecLabel(),
                index: null,
            }
        }
    },
    mounted() {
        //storeに前回の検索条件があれば
        this.condition = this.$store.getters['condition/merge']('SpecLabel', this.condition);
        this.search();
    },
    computed: {
    },
    methods: {
        //検索
        search() {
            //表示データ初期化
            this.spec_labels.splice(0);
            //部分ローダー
            this.loading = true;
            this.$http.get('/spec-label', {
                params: this.condition
            })
            .then(response => {
                for (let row of response.data) {
                    this.spec_labels.push(new SpecLabel(row));
                }
                //検索条件を保存
                this.$store.commit('condition/setSearchCondition', {
                    page: 'UserList',
                    condition: this.condition
                });

            })
            .finally(() => {
                this.loading = false;
            });
        },
        //削除対象の規格項目をセットする
        setTargetSpecLabel(spec_label = new SpecLabel(), index = null) {
            this.delete_target.spec_label = spec_label;
            this.delete_target.index = index;
        },
        //新規登録
        create() {
            this.startScreenLoading();

            this.$http.post('/spec-label', this.additional_spec_label)
            .then(response => {
                this.spec_labels.push(new SpecLabel(response.data));
                this.additional_spec_label = new SpecLabel();
                this.showMessage('追加しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //編集
        update(spec_label) {
            this.startScreenLoading();

            this.$http.put(`/spec-label/${spec_label.spec_label_id}`, spec_label)
            .then(() => {
                this.showMessage('編集しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //削除
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/spec-label/${this.delete_target.spec_label.spec_label_id}`)
            .then(() => {
                this.spec_labels.splice(this.delete_target.index, 1);
                this.showMessage('削除しました');

                //値をリセット
                this.setTargetSpecLabel();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        }
    }
}
</script>

<style scoped>

</style>
